import Plausible from '@/plausible';
import md5 from '@/utils/md5';

const plausibleApiHost = window.vueConfig.VUE_APP_PLAUSIBLE_API_HOST;
let vueTrackEvent;

/**
 * Initialize Plausible
 */
function initPlausible(): void {
    try {
        if (plausibleApiHost && Plausible) {
            const { enableAutoPageviews, trackEvent, enableAutoOutboundTracking } = Plausible({
                apiHost: plausibleApiHost,
                trackLocalhost: true,
            });

            enableAutoPageviews();
            enableAutoOutboundTracking();
            vueTrackEvent = trackEvent;
        }
    } catch (error) {
        /* eslint-disable no-console */
        console.error('Error initializing Plausible:', error);
    }
}

initPlausible();

/**
 * Create Plausible track event
 * @param eventName
 * @param params
 */
export function plausibleTrack(eventName: string, params?: any): void {
    try {
        if (plausibleApiHost && vueTrackEvent) {
            vueTrackEvent(eventName, {
                props: params,
            });
        }
    } catch (error) {
        /* eslint-disable no-console */
        console.error(error);
    }
}

const basicParams = (memberInfo) => {
    return {
        Email: md5(memberInfo.login_name),
        'Member Country': memberInfo.country,
    };
};

export class PlausibleHandler {
    login(memberInfo, params): void {
        plausibleTrack('Button Clicked', { Email: md5(params.email) });
    }
    signupLive(memberInfo, params): void {
        const payload = {};
        if (params?.email) {
            payload['Email'] = md5(params.email);
        }
        if (params?.country) {
            payload['Member Country'] = params.country;
        }
        plausibleTrack('KYC Submit', {
            ...payload,
            Step: 'Step 0',
        });
    }
    signupDemo(memberInfo, params): void {
        const payload = {};
        if (params?.email) {
            payload['Email'] = md5(params.email);
        }
        if (params?.country) {
            payload['Member Country'] = params.country;
        }
        plausibleTrack('KYC Submit', {
            ...payload,
            Step: 'Step 0',
        });
    }
    KYCSubmit(memberInfo, params): void {
        plausibleTrack('KYC Submit', {
            ...basicParams(memberInfo),
            Step: params.step,
        });
    }
    KYCBack(memberInfo, params): void {
        plausibleTrack('KYC Back', {
            ...basicParams(memberInfo),
            Step: params.step,
        });
    }
    fundingDeposit(memberInfo, params): void {
        const payload = {
            'Funding Method': params.funding_method,
            Type: 'Deposit',
            'Trading Account': md5(params.trading_account_id),
            'Trading Server': params.trading_server,
            Amount: params.amount,
            'Payment Currency': params.payment_currency,
            ...basicParams(memberInfo),
        };
        plausibleTrack('Funding Submit', payload);
    }
    fundingWithdraw(memberInfo, params): void {
        const payload = {
            'Funding Method': params.funding_method,
            Type: 'Withdraw',
            'Trading Account': md5(params.trading_account_id),
            'Trading Server': params.trading_server,
            Amount: params.amount,
            'Payment Currency': params.payment_currency,
            ...basicParams(memberInfo),
        };
        plausibleTrack('Funding Submit', payload);
    }
    promotionClicked(memberInfo, params): void {
        const payload = {
            ...basicParams(memberInfo),
            crm_user_id: memberInfo.login_id,
            utm_activity_id: params.promotion_id,
        };
        plausibleTrack('Promotion Clicked', payload);
    }
    tradingCompetitionClick(memberInfo, params): void {
        const payload = {
            ...basicParams(memberInfo),
            Type: params.type,
            'Trading Account': params.ta,
            Ranking: params.ranking,
        };
        if (params?.week) {
            payload['Week'] = params?.week;
        }
        plausibleTrack('Trading Competition', payload);
    }
}
