import { LoginTokenData } from '@/model/utils/cookie';
import router from '@/router';
import { useAppStore } from '@/stores/app';
import { SET_UPLOAD_PROGRESS } from '@/stores/app/actionTypes';
import ACCEPT from '@/utils/acceptOptions';
import { errorHandler, removeEmptyValue } from '@/utils/axiosHandler';
import { getLocalStorage } from '@/utils/localStorage';
import { removeLoginInfo, requestExtendExpire, tokenKey } from '@/utils/login';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as _ from 'lodash-es';
import qs from 'qs';
import { FormDataType, Objects, RequestParams, ResponseError } from 'src/model/common';

axios.defaults.baseURL = window.vueConfig.VUE_APP_API || `${window.location.origin}/api`;

const mockUrl = 'https://dev-mock.tm-nonprod.com/mock/5fbb4e9cb7168707b38490ea/api';

const CancelToken = axios.CancelToken;
let source = CancelToken.source();

// 终止后续请求
function cancelRequest() {
    source.cancel('Request Cancelled');
    source = CancelToken.source();
}

// 这里的 errorCode，代表的意思是后台有部分内容成功，部分失败。但是总的结果来说是成功。提示的 message 由后台自定义
// 请求发生错误，前端需要根据 errorCode 进行下一步操作, 设置 errorCode 白名单
// 82000, 82001, 82002, 82003, 82004 这四个白名单是用在 demoTa 的注册, 41009 是推荐码错误
// 80080 DemoTA 达到上限(80079 是 liveTA 达到上限)
// 80051 用在注册 step0 交互用
// 10009 member funding withdraw 添加下单 2 分钟间隔
// 40022, 40046: registration age restrictions
// 80132,80131 ,80126 ,80127 ,80128 ,80129 ,80133 ,80130 ,80134 trading competition 注册时，可能遇到的error
const errorCode = [10004, 40032];
const errorCodeWLB = [
    10009, 40103, 40105, 70021, 60013, 81005, 82000, 82001, 82002, 82003, 82004, 41009, 80051, 80080, 41045, 41051, 41054, 42001, 44014, 44015, 44016, 44017, 44018, 44019, 44020, 44021, 40022, 40046, 40100, 42004, 44024,
    44025, 44027, 60019, 80089, 80092, 80100, 80104, 80132, 80131, 80126, 80127, 80128, 80129, 80133, 80130, 80134, 81022, 81021, 80205, 80206, 80208, 80209, 80201, 80211, 87001, 87004, 87009, 87010, 80109, 80113, 80114,
    100004,
];
const logoutErrorCode = [40001, 40013, 40014, 40015, 40016];

/**
 *
 *
 * @param {*} url 请求 url
 * @param {*} [params={}] 请求参数
 * @param {string} [method='post'] 请求方法
 * @param {boolean} [mock=false] 是否使用 mock
 * @param {string} [accept='prs-v2'] 请求的 api 版本
 * @param {boolean} [isFormData=false] 参数是否附带文件
 * @param {boolean} [showProgressBar=false] 是否显示进度条
 * @param {*} responseType 定义接口返回值的类型
 * @param {boolean} [showError=true] 在接口报错时，是否提示错误
 * @param {boolean} [emitError=false] 在接口报错时，是否返回错误信息
 * @param {string} [suffix=true] 是否允许添加接口后缀
 * @param {string} [checkValue=false] 是否在请求 api 前去除空值，以及去除 string 类型参数的前后空格
 * @param {string} [isLanguages=false] 参数是否是多语言相关功能参数（非多语言上传多个文件时需判断）
 */
export async function reqJsonData<T = any>({
    url,
    params = {},
    method = 'post',
    mock = false,
    accept = 'prs-v2',
    isFormData = false,
    showProgressBar = false,
    responseType,
    checkValue = false,
    showError = true,
    emitError = false,
}: RequestParams): Promise<T | undefined> {
    const config: AxiosRequestConfig = {
        method,
        url,
        cancelToken: source.token,
        headers: {
            'Content-Type': !isFormData ? 'application/x-www-form-urlencoded' : 'multipart/form-data', // 带有表单数据时的 Content-Type
            Accept: ACCEPT[accept] as string,
            'If-Modified-Since': '0', // 防止 ie 浏览器的 ajax 的缓存
        },
    };
    if (checkValue) {
        params = removeEmptyValue(params);
    }
    if (responseType) {
        config.responseType = responseType;
    }
    if (mock) {
        config.baseURL = mockUrl;
    }
    if (method === 'get' || method === 'GET') {
        config.params = params;
        config.paramsSerializer = (params) => {
            return qs.stringify(params, { arrayFormat: 'indices' });
        };
    } else {
        config.data = qs.stringify(params);
    }

    const transformRequest = new FormData();
    const appStore = useAppStore();
    const setUploadProgress = (value: number | boolean) => {
        appStore[SET_UPLOAD_PROGRESS](value);
    };
    if (showProgressBar) {
        config.onUploadProgress = (event) => {
            let rateNumber = ((event.loaded / (event.total ?? 1)) * 100) | 0;
            if (rateNumber === 100) {
                rateNumber = 99;
            }
            setUploadProgress(rateNumber);
        };
    }
    if (isFormData) {
        for (const key in params) {
            if (Array.isArray(params[key])) {
                (params[key] as Objects[]).forEach((element: Objects | any, index: number) => {
                    if (Array.isArray(element) || typeof element === 'object') {
                        for (const k in element) {
                            if (Array.isArray(element[k])) {
                                (element[k] as FormDataType[]).forEach((el, i: number) => {
                                    transformRequest.append(`${key}[` + index + `][${k}][` + i + `]`, el);
                                });
                            } else {
                                transformRequest.append(`${key}[` + index + `][${k}]`, element[k]);
                            }
                        }
                    } else {
                        transformRequest.append(`${key}[` + index + `]`, element);
                    }
                });
            } else {
                /* 如需组成文件数组参数，组件内数据为
				{ files: [file, file....] }
				这里用 append 变成正常的
				{ key: [file, file....] }
				*/
                if (!_.isEmpty(params[key])) {
                    const hasFileKey = Object.keys(params[key]);
                    if (hasFileKey.length === 1 && hasFileKey[0] === 'files') {
                        for (const v of (params[key] as Objects)[hasFileKey[0]]) {
                            transformRequest.append(`${key}[]`, v);
                        }
                    } else {
                        transformRequest.append(key, params[key]);
                    }
                } else {
                    transformRequest.append(key, params[key]);
                }
            }
        }
    }
    return new Promise((resolve) => {
        try {
            const newAxios = !isFormData ? axios(config) : axios.post<T>(url, transformRequest, config);
            newAxios
                .then((res: AxiosResponse<T | undefined>) => {
                    if (showProgressBar) {
                        setUploadProgress(100);
                        setTimeout(() => {
                            return setUploadProgress(false);
                        }, 2000);
                    }
                    const isResponseError = (resData: any): resData is ResponseError => {
                        let result = false;
                        if (resData && typeof resData === 'object' && ('code' in resData || 'debug' in resData)) {
                            result = true;
                        }
                        return result;
                    };
                    if (isResponseError(res.data)) {
                        // 后台可能返回字符串 code
                        const code = Number(res.data?.code);

                        if ([40041, 40047].includes(code)) {
                            errorHandler(res.data);
                            router.push({ path: '/no-access' });

                            return resolve(undefined);
                        }

                        // 如果返回的是跟 token 相关的错误 code 信息，需要重新登录，而不是继续请求
                        if (logoutErrorCode.includes(code)) {
                            errorHandler(res.data);
                            cancelRequest();
                            removeLoginInfo();
                            router.push({ path: '/login' });
                            return resolve(undefined);
                        }
                        if (errorCodeWLB.includes(code)) {
                            return resolve(res.data as T);
                        }
                        if (!errorCode.includes(code)) {
                            if (showError) errorHandler(res.data);
                            if (emitError) resolve(res.data);
                            return resolve(undefined);
                        }
                    }

                    /*
                    一般来说，后台不做任何返回值，默认的后台返回值会为空 object。即使后台自定义了空值返回，
                    在这里添加个默认返回值{}，因此在调用 api 时，只需对 res 进行 if 判断即可证明 api 是否正确调用成功(空 object 在 if 判断中是为 true 的)
                */
                    resolve(res.data || ({} as T));
                })
                .catch((err) => {
                    // 如果后台报错，则不需要显示 100%。直接让进度条消失
                    showProgressBar &&
                        setTimeout(() => {
                            return setUploadProgress(false);
                        }, 2000);
                    if (err.response && err.response.status === 401) {
                        cancelRequest();
                        removeLoginInfo();
                        router.push({ path: '/login' });
                        return resolve(undefined);
                    } else if (err.response && err.response.status === 417) {
                        if (responseType === 'arraybuffer') {
                            const enc = new TextDecoder('utf-8');
                            err.response.data = JSON.parse(enc.decode(new Uint8Array(err.response.data)));
                        }
                        if (err.response.data.code || err.response.data.debug) {
                            if (!errorCode.includes(err.response.data.code)) {
                                errorHandler(err.response.data);
                                return resolve(undefined);
                            }
                        }
                    }
                    errorHandler(err);
                    return resolve(undefined);
                });
        } catch (error: any) {
            resolve(undefined);
            errorHandler(error);
        }
    });
}

axios.interceptors.request.use((config: any) => {
    const localToken = getLocalStorage<LoginTokenData>(tokenKey)?.access_token;
    if (localToken) {
        const token = `Bearer ${localToken}`;
        config.headers['Authorization'] = token;
    }
    return config;
});

axios.interceptors.request.use((config: any) => {
    if (config.url !== '/authorizations/currentMember') {
        requestExtendExpire();
    }
    return config;
});
